import React, { useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { initAjaxSelectpicker } from 'components/form/utils'

const RecipientFilter = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const ref = useRef()

  const onChange = useCallback(({ target: { selectedOptions } }) => {
    const recipient_ids = Array.from(selectedOptions).map(({ value }) => value)
    navigate(null, { state: { ...state, recipient_ids } })
  }, [state])

  useEffect(() => {
    const ajax = { url: '/contacts', title: 'Destinataire du rendez-vous' }
    initAjaxSelectpicker(ref.current, ajax)
  }, [])

  return (
    <div className='pb-2'>
      <select
        ref={ref}
        className='agenda-dropdown w-100 agenda-calendar-dropdown'
        multiple
        onChange={onChange}
      />
    </div>
  )
}

export default RecipientFilter