import React from 'react'
import PropTypes from 'prop-types'

const ConfirmNotificationText = ({ date }) => {
  const sendable = moment().isBefore(date)
  const sendText = '(une notification sera envoyée aux participants)'
  const unsendText = '(aucune notification ne sera envoyée aux participants)'
  const sendableText = sendable ? sendText : unsendText
  const sendableClass = sendable ? 'text-success' : 'text-danger'

  return (
    <h5 className={sendableClass}>{sendableText}</h5>
  )
}

ConfirmNotificationText.propTypes = {
  date: PropTypes.string.isRequired
}

export default ConfirmNotificationText
