import React from 'react'
import { Icon } from 'components'
import { renderToString } from 'react-dom/server'

const addEventsIcon = () => {
  const confirmIcon = `${renderToString(<Icon name="question" title="En attente d'action" />)} `
  const refusedIcon = `${renderToString(<Icon name="times" title="Créneau refusé" />)} `

  $('.fc-event.event-confirmation .fc-event-time:not(:has(>i))').prepend(confirmIcon)
  $('.fc-event.event-refused .fc-event-time:not(:has(>i))').prepend(refusedIcon)
  $('.fc-list-event.event-confirmation .fc-list-event-graphic:not(:has(>i))').append(` ${confirmIcon}`)
  $('.fc-list-event.event-refused .fc-list-event-graphic:not(:has(>i))').append(` ${refusedIcon}`)
  $('.fc-event [data-toggle="tooltip"]:not([data-original-title])').tooltip()
}

export { addEventsIcon }
