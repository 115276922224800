import React from 'react'
import { createRoot } from 'react-dom/client'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import classNames from 'classnames'
import { addEventsIcon } from './icon'
import { getAppointments } from 'pages/agenda/actions'
import { ListTableHeader, CalendarList } from 'pages/agenda/components'

const calendarStructure = element => {
  const classes = [`event-color-${element.service.color}`, `event-${element.status}`]

  return {
    id: element.id,
    classNames: classNames(classes),
    status: element.status,
    title: element.agenda_label,
    recipientName: `${element.recipient.last_name} ${element.recipient.first_name}`,
    serviceName: element.service.name,
    serviceColor: element.service_color,
    operator: element.operator,
    location: element.location,
    start: element.start_at,
    end: element.end_at
  }
}

const initialView = calendar => {
  switch (calendar) {
  case 'week':
    return 'timeGridWeek'
  case 'list':
    return 'listMonth'
  case 'day':
    return 'timeGridDay'
  default:
    return 'dayGridMonth'
  }
}

const scrollToDate = day => {
  const elements = document.getElementsByClassName('fc-list-day')

  if (!elements.length)
    return

  const days = Array.from(elements).map(({ dataset }, index) => (
    { key: index, value: moment(dataset.date).format('D') }
  ))

  const dayToScroll = days.reduce((prev, curr) => (
    Math.abs(curr.value - day) < Math.abs(prev.value - day) ? curr : prev
  ))

  document.querySelector('.fc-scroller').scrollTop = elements[dayToScroll.key].offsetTop
  elements[dayToScroll.key].classList.add('fc-active')
}

const events = ({ data, state, date }) =>
  getAppointments({ ...data, ...state, date }).then(res => res.map(calendarStructure))


const addCustomTableHeader = el => {
  if (!el.previousSibling.classList.contains('fc-list-event')) {
    const tr = document.createElement('tr')

    el.previousSibling.children[0].colSpan = 6
    createRoot(tr).render(<ListTableHeader />)
    el.before(tr)
  }
}

const addCustomTableLines = (el, props) => {
  const tr = document.createElement('tr')

  createRoot(tr).render(
    <CalendarList
      recipientName={props.recipientName}
      operator={props.operator}
      location={props.location}
    />
  )

  requestIdleCallback(() => el.append(...tr.children))
}

const eventDidMount = async({ el, event: { extendedProps, _context }, view: { type } }) => {
  if (type === 'listMonth') {
    await addCustomTableHeader(el)
    await addCustomTableLines(el, extendedProps)
    scrollToDate(moment(_context.options.initialDate).format('D'))
  }

  addEventsIcon()
}

const viewDidMount = ({ event: { view: { type }, el }, date }) => {
  if (type === 'dayGridMonth')
    el.querySelector(`[data-date="${date}"]`).classList.add('fc-date-selected')
}


const settings = {
  locales: [frLocale],
  dayMaxEvents: true,
  dayMaxEventRows: true,
  nowIndicator: true,
  editable: true,
  eventMaxStack: 4,
  headerToolbar: false,
  themeSystem: 'bootstrap',
  locale: 'fr',
  timeZone: 'Europe/Paris',
  listDayFormat: {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    weekday: 'long'
  },
  listDaySideFormat: false,
  plugins: [
    bootstrapPlugin,
    dayGridPlugin,
    interactionPlugin,
    listPlugin,
    timeGridPlugin,
    momentTimezonePlugin
  ],
  views: {
    timeGrid: {
      allDaySlot: false,
      slotDuration: '00:30:00',
      scrollTime: moment().set({ hour: 7, minute: 0 }).format('H:00'),
      dayMaxEventRows: 4
    }
  }
}

export { initialView, events, eventDidMount, viewDidMount, settings }
