import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

const Header = ({ title = '', icon = null, closeModal }) => (
  <div className="modal-header">
    <h5 className="modal-title">
      { icon ? (
        <Icon
          className="mr-3"
          name={icon.name}
          title={icon.title}
          type={icon.type}
        />
      ) : null }
      {title}
    </h5>
    <button className="close" type="button" onClick={closeModal}>
      <Icon name="times" title="close" />
    </button>
  </div>
)

Header.propTypes = {
  closeModal: PropTypes.func.isRequired,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  title: PropTypes.string
}

export default Header