import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Icon = ({ className = '', type = 'fas', name, title = '' }) => {
  const ref = useRef()
  const tooltip = title.length ? 'tooltip' : ''
  const classNames = `${type} fa-${name} ${className}`

  useEffect(() => { $(ref.current).tooltip() }, [])

  return <i ref={ref} className={classNames} title={title} data-toggle={tooltip} />
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string
}

export default Icon
