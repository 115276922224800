import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Libraries
import ReactRailsUJS from 'react_ujs'
import moment from 'moment'
import 'ajax-bootstrap-select'
import 'ajax-bootstrap-select/dist/js/locale/ajax-bootstrap-select.fr-FR'
import 'chartkick/chart.js'
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'

const componentRequireContext = require.context('pages', true)

ReactRailsUJS.useContext(componentRequireContext)

moment.locale('fr')
window.moment = moment

Chart.register(annotationPlugin)
