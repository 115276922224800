import React from 'react'
import PropTypes from 'prop-types'

const Modal = ({ modalRef, children }) => (
  <div className="modal fade" ref={modalRef}>
    <div className="modal-dialog">
      <div className="modal-content">
        {children}
      </div>
    </div>
  </div>
)

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalRef: PropTypes.exact({
    current: PropTypes.instanceOf(HTMLDivElement)
  }).isRequired
}

export default Modal