import React, { useRef, useCallback, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateAppointmentSchedules } from 'pages/agenda/actions'
import { strDate } from 'pages/agenda/utils/date'
import { Modal, ModalHeader } from 'components'
import { ConfirmAction, ConfirmNotificationText } from '.'

const EditAppointmentSchedules = () => {
  const navigate = useNavigate()
  const modalRef = useRef()
  const { id } = useParams()
  const { state } = useLocation()
  const [startAt, endAt] = [strDate(state?.editStartAt), strDate(state?.editEndAt)]

  const removeDateState = () => ['editStartAt', 'editEndAt'].map(el => delete state[el])

  const closeModal = useCallback(() => {
    $(modalRef.current).modal('hide')
    removeDateState()
    navigate('/agenda', { state })
  }, [modalRef, state])

  const declineAction = useCallback(() => closeModal())

  const confirmAction = useCallback(() => {
    updateAppointmentSchedules({ id, startAt, endAt })
      .then(() => closeModal())
      .catch(({ message }) => toast.error(message))
  }, [state])

  useEffect(() => {
    $(modalRef.current).on('hidden.bs.modal',() => closeModal())
    $(modalRef.current).modal('show')
  }, [])

  return (
    <Modal modalRef={modalRef}>
      <ModalHeader
        title="Edit de la date du rendez-vous"
        icon={{ name: 'calendar-alt', title: 'Edit de la date du rendez-vous' }}
        closeModal={closeModal}
      />
      <ConfirmAction declineAction={declineAction} confirmAction={confirmAction} >
        <ConfirmNotificationText date={endAt} />
      </ConfirmAction>
    </Modal>
  )
}

export default EditAppointmentSchedules
