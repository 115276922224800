import React, { useContext, useEffect, useRef, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AgendaContext } from 'pages/agenda/contexts'

const ServiceFilter = () => {
  const { services } = useContext(AgendaContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const ref = useRef()
  const serviceIds = state?.service_ids || services.map(service => service[0])

  const onChange = useCallback(({ target: { selectedOptions } }) => {
    const service_ids = Array.from(selectedOptions).map(({ value }) => value)
    navigate(null, { state: { ...state, service_ids } })
  }, [state])

  useEffect(() => { $(ref.current).selectpicker({ dropdownAlignRight: true }) }, [])

  return (
    <div className='pb-2'>
      <select
        value={serviceIds}
        ref={ref}
        className='agenda-dropdown w-100'
        data-none-selected-text="Tous les services"
        data-actions-box
        multiple
        onChange={onChange}
      >
        {services.map(([id, name, color]) => (
          <option className={`circle-pin-${color}`} value={id} key={id}>{name}</option>
        ))}
      </select>
    </div>
  )
}

export default ServiceFilter