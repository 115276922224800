import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { strDate } from 'pages/agenda/utils/date'
import { setLocale, object, string } from 'yup'
import {
  getAppointment,
  updateAppointment,
  redoAppointment,
  confirmAppointment,
  deleteAppointment,
  refuseAppointment,
  cancelAppointment
} from 'pages/agenda/actions'
import { fr } from 'locales/yup'
import { Modal, ModalHeader } from 'components'
import { ConfirmAction, ConfirmNotificationText } from '../confirmAction'
import Form from './Form'

const EditAppointment = () => {
  const navigate = useNavigate()
  const modalRef = useRef()
  const [validateOnChange, setValidateOnChange] = useState(false)
  const [editBodyMode, setEditBodyMode] = useState('form')
  const { state } = useLocation()
  const { id } = useParams()

  const actions = {
    destroy: deleteAppointment,
    confirm: confirmAppointment,
    refuse: refuseAppointment,
    cancel: cancelAppointment,
    redo: redoAppointment
  }

  const initialValues = {
    id,
    userId: '',
    serviceId: '',
    service: {},
    recipient: {},
    recipientId: '',
    startAt: strDate(),
    endAt: strDate(),
    participants: [],
    location: '',
    participantIds: [],
    channel: 'disabled',
    reminderDelay: 0,
    cancellable: false,
    cancellationDelay: 0,
    reviveAt: '',
    status: '',
    errorMessage: ''
  }

  const validationSchema = object({
    recipientId: string().label('Le destinataire').required(),
    serviceId: string().label('Le service').required(),
    startAt: string().required(),
    endAt: string().label('La date de fin').required().test(
      (date, { parent: { startAt } }) => moment(startAt).isBefore(date)
    )
  })

  const closeModal = useCallback(() => {
    $(modalRef.current).modal('hide')
    navigate('/agenda', { state })
  }, [modalRef, state])

  const onSubmit = useCallback(() => setEditBodyMode('update'))
  const declineAction = useCallback(() => setEditBodyMode('form'))
  const confirmAction = useCallback(() => actions[editBodyMode](id).then(() => closeModal()))

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange,
    onSubmit
  })

  const confirmUpdate = useCallback(() => {
    updateAppointment(formik.values)
      .then(() => closeModal())
      .catch(({ message }) => formik.setFieldValue('errorMessage', message))
  }, [formik.values])

  useEffect(() => {
    if (!formik.isValid)
      setValidateOnChange(true)
  }, [formik.isValid])

  useEffect(() => {
    setLocale(fr)
    $(modalRef.current).on('hidden.bs.modal',() => closeModal())

    getAppointment(id, state?.userId).then(data => {
      formik.setFieldValue('serviceId', data.service.id)
      formik.setFieldValue('service', data.service)
      formik.setFieldValue('recipient', data.recipient)
      formik.setFieldValue('recipientId', data.recipient.id)
      formik.setFieldValue('startAt',  strDate(data.start_at))
      formik.setFieldValue('endAt', strDate(data.end_at))
      formik.setFieldValue('location', data.location)
      formik.setFieldValue('participants', data.participants)
      formik.setFieldValue('participantIds', data.participants.map(ps => ps.id))
      formik.setFieldValue('channel', data.channel)
      formik.setFieldValue('reminderDelay', data.reminder_delay)
      formik.setFieldValue('reviveAt', data.revive_at || '')
      formik.setFieldValue('status', data.status)

      $(modalRef.current).modal('show')
    })
  }, [])

  const renderEditBody = () => {
    switch(editBodyMode) {
    case 'destroy':
    case 'confirm':
    case 'refuse':
    case 'cancel':
    case 'redo':
      return (
        <ConfirmAction
          declineAction={declineAction}
          confirmAction={confirmAction}
          type={editBodyMode}
        />
      )
    case 'update':
      return (
        <ConfirmAction declineAction={declineAction} confirmAction={confirmUpdate}>
          <ConfirmNotificationText date={formik.values.endAt} />
        </ConfirmAction>
      )
    default:
      return <Form formik={formik} setEditBodyMode={setEditBodyMode} />
    }
  }

  return (
    <Modal modalRef={modalRef}>
      <ModalHeader
        title="Edit du rendez-vous"
        icon={{ name: 'calendar-alt', title: 'Edit du rendez-vous' }}
        closeModal={closeModal}
      />
      {renderEditBody()}
    </Modal>
  )
}

export default EditAppointment
