import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { InputDate, Icon, IconButton } from 'components'
import { AgendaContext } from 'pages/agenda/contexts'
import { AppointmentForm } from 'pages/agenda/components'
import { formikPropTypes } from 'components/form/utils'

const Form = ({ formik, setEditBodyMode }) => {
  const { currentUserRole } = useContext(AgendaContext)
  const isSameOrBeforeStart = moment().isSameOrBefore(formik.values.startAt)
  const isDeletable = ['admin', 'staff'].includes(currentUserRole)
  const isConfirmable = formik.values.status === 'confirmation' && isSameOrBeforeStart
  const isRefusable = formik.values.status === 'confirmation' && isSameOrBeforeStart
  const isCancelable = ['preset', 'approved'].includes(formik.values.status) && isSameOrBeforeStart
  const isRedoable = formik.values.status === 'preset' && isSameOrBeforeStart
  const isFromThePast = moment(formik.values.startAt).isBefore(moment())

  const iconButtons = [
    {
      isActive: isDeletable,
      type: 'danger',
      title: 'Supprimer',
      iconName: 'trash',
      onClick: () => setEditBodyMode('destroy')
    },
    {
      isActive: isConfirmable,
      type: 'success',
      title: 'Confirmer',
      text: 'Confirmer',
      iconName: 'check',
      onClick: () => setEditBodyMode('confirm')
    },
    {
      isActive: isRefusable,
      type: 'danger',
      title: 'Refuser',
      text: 'Refuser',
      iconName: 'ban',
      onClick: () => setEditBodyMode('refuse')
    },
    {
      isActive: isCancelable,
      type: 'danger',
      title: 'Annuler',
      iconName: 'ban',
      onClick: () => setEditBodyMode('cancel')
    },
    {
      isActive: isRedoable,
      type: 'danger',
      title: 'Relancer',
      iconName: 'redo',
      onClick: () => setEditBodyMode('redo')
    }
  ]

  return (
    <>
      <div className="modal-body">
        <AppointmentForm formik={formik} isFromThePast={isFromThePast} />
        <InputDate
          name="appointment[revive_at]"
          field="reviveAt"
          formik={formik}
          error={formik.errors.reviveAt}
          placeholder="Aucune relance..."
          formClass="mt-3 mb-0"
          icon={{ name: 'redo',
            title: 'Date de la dernière relance' }}
          disabled={isFromThePast}
        />
      </div>
      <div className="modal-footer">
        <a className="btn btn-primary" title="Détail" href={`/appointments/${formik.values.id}`}>
          <Icon name="eye" />
        </a>
        {iconButtons.filter(({ isActive }) => isActive).map(btn => (
          <IconButton
            key={btn.title}
            type={btn.type}
            title={btn.title}
            text={btn.text}
            iconName={btn.iconName}
            className="ml-1"
            onClick={btn.onClick} // eslint-disable-line react/jsx-handler-names
          />
        ))}
        <button
          className="btn btn-primary ml-1"
          type="button"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          Sauvegarder
        </button>
      </div>
    </>
  )
}

Form.propTypes = {
  formik: formikPropTypes.isRequired,
  setEditBodyMode: PropTypes.func.isRequired
}

export default Form
