import React, { useEffect, useRef, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initAjaxSelectpicker, formikPropTypes } from './utils'

const defaultCallback = () => null
const defaultIcon = {}

const AjaxSelect = ({
  name = '',
  field,
  formik,
  error = '',
  url,
  title = null,
  icon = defaultIcon,
  multiple = false,
  options = null,
  callback = defaultCallback,
  disabled = false
}) => {
  const selectRef = useRef()
  const inputClasses = classNames('form-control', { 'is-invalid': Boolean(error) })
  const selectTitle = multiple ? null : title

  useEffect(() => initAjaxSelectpicker(selectRef.current, { url, title: selectTitle }), [])

  useEffect(() => { $(selectRef.current).selectpicker('refresh') })

  const onChange = useCallback(({ target: { selectedOptions } }) => {
    const arrayOptions = Array.from(selectedOptions)
    const value = arrayOptions.map(option => option.value)

    callback(arrayOptions)
    formik.setFieldValue(field, multiple ? value : value[0])
  }, [field, multiple])

  return (
    <div className="form-group row">
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>
      <div className="col-11">
        <select
          className={inputClasses}
          ref={selectRef}
          value={formik.values[field]}
          name={name}
          multiple={multiple}
          disabled={disabled}
          onChange={onChange}
        >
          {options?.map(option => (
            <option key={`option_${option.key}`} value={option.key}>{option.value}</option>
          ))}
        </select>
        {error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

AjaxSelect.propTypes = {
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: formikPropTypes.isRequired,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.number,
      value: PropTypes.string
    })
  ),
  title: PropTypes.string,
  url: PropTypes.string.isRequired
}

const isMemoizable = (prev, next) =>
  prev.disabled === next.disabled &&
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]


export default memo(AjaxSelect, isMemoizable)
