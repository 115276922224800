import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const LinkToAvailabilities = ({ mobile = false }) => {
  const parentClasses = classNames({
    'sidebar-availability': !mobile,
    'pb-2 pt-3': mobile
  })

  const linkClasses = classNames('sidebar-calendar btn btn-availability w-100', {
    'sidebar-btn py-2': !mobile
  })

  return (
    <div className={parentClasses}>
      <a className={linkClasses} href="/availabilities">
        Gérer les disponibilités
      </a>
    </div>
  )
}

LinkToAvailabilities.propTypes = {
  mobile: PropTypes.bool
}

export default LinkToAvailabilities