import React from 'react'
import PropTypes from 'prop-types'

const ConfirmAction = ({ declineAction, confirmAction, type = 'edit', children = null }) => {
  const types = {
    edit: 'modifier',
    destroy: 'supprimer',
    confirm: 'confirmer',
    refuse: 'refuser',
    cancel: 'annuler',
    redo: 'relancer'
  }

  return (
    <>
      <div className="modal-body">
        <h4>
          {`Êtes-vous sûr de vouloir ${types[type]} ce rendez-vous ?`}
        </h4>
        {children}
      </div>
      <div className="modal-footer">
        <button className="btn btn-secondary mr-1" type="button" onClick={declineAction}>
          Non
        </button>
        <button className="btn btn-primary" type="button" onClick={confirmAction}>
          Oui, j&apos;en suis sûr
        </button>
      </div>
    </>
  )
}

ConfirmAction.propTypes = {
  children: PropTypes.node,
  confirmAction: PropTypes.func.isRequired,
  declineAction: PropTypes.func.isRequired,
  type: PropTypes.string
}

export default ConfirmAction
