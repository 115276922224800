import React from 'react'
import PropTypes from 'prop-types'

const CalendarList = ({
  recipientName = '...',
  operator = '...',
  location = '...'
}) => (
  <>
    <td>{recipientName}</td>
    <td>{operator || '...'}</td>
    <td>{location || '...'}</td>
  </>
)

CalendarList.propTypes = {
  location: PropTypes.string,
  operator: PropTypes.string,
  recipientName: PropTypes.string
}

export default CalendarList
