import React, { useRef, useContext, useCallback } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import { AgendaContext } from 'pages/agenda/contexts'
import { Header, SideBar, LinkToAvailabilities, LinkToExportPdf, LinkToCalendars } from '.'
import { initialView, events, eventDidMount, viewDidMount, settings } from 'pages/agenda/utils/calendar'
import { formatNewDateTime } from 'pages/agenda/utils/date'

const Calendar = () => {
  let data = useContext(AgendaContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const calendarRef = useRef()

  data = {
    ...data,
    userId: state?.userId || data.currentUserId,
    agendaLabel: state?.agendaLabel || data.currentAgendaLabel
  }

  const { date, calendar } = {
    date: state?.date || moment().format('YYYY-MM-DD'),
    calendar: state?.calendar || 'month'
  }

  const onEvents = useCallback(() => events({ data, state, date }), [data, state, date])

  const onViewDidMount = useCallback(event => viewDidMount({ event, date }), [date])

  const dateClick = useCallback(event => {
    const newDate = formatNewDateTime(event.date)
    navigate('new_appointment', { state: { ...state, userId: data.userId, newDate } })
  }, [state])

  const eventClick = useCallback(({ event: { id } }) => {
    navigate(`edit_appointment/${id}`, { state: { ...state, userId: data.userId } })
  }, [state, data.userId])

  const eventUpdate = useCallback(info => {
    navigate(`edit_appointment_schedules/${info.event.id}`, {
      state: {
        ...state,
        userId: data.userId,
        editStartAt: info.event.start,
        editEndAt: info.event.end
      }
    })
  }, [state, data.userId])

  return (
    <AgendaContext.Provider value={data}>
      <div className="agenda-calendar" key={`${calendar}-${date}`}>
        <Header date={date} />
        <div className='row'>
          <div className='col pr-0 calendar-order'>
            <FullCalendar
              ref={calendarRef}
              initialDate={date}
              initialView={initialView(calendar)}
              events={onEvents}
              eventDidMount={eventDidMount}
              viewDidMount={onViewDidMount}
              dateClick={dateClick}
              eventClick={eventClick}
              eventDrop={eventUpdate}
              eventResize={eventUpdate}
              {...settings} // eslint-disable-line react/jsx-props-no-spreading
            />
          </div>
          <div className='col-sidebar pl-0'>
            <SideBar date={date} calendar={calendar} />
          </div>
        </div>
        <div className='calendar-mobile-only'>
          <LinkToAvailabilities mobile />
          <LinkToCalendars mobile />
          <LinkToExportPdf date={date} mobile />
        </div>
        <Outlet />
      </div>

    </AgendaContext.Provider>
  )
}

export default Calendar
