import React, { useEffect, useRef, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initFlatpickr, formikPropTypes } from './utils'

const defaultIcon = {}

const InputDate = ({
  name = '',
  field,
  formik,
  error = '',
  icon = defaultIcon,
  formClass = '',
  disabled = false,
  ...rest
}) => {
  const inputRef = useRef()
  const formClasses = classNames('form-group row', formClass)
  const inputClasses = classNames('form-control', { 'is-invalid': Boolean(error) })

  const onClose = useCallback((_selectedDates, dateStr, _instance) => {
    formik.setFieldValue(field, dateStr)
  }, [field])

  const onChange = useCallback(() => null)

  useEffect(() => initFlatpickr(inputRef, onClose))

  return (
    <div className={formClasses}>
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>
      <div className="col-11">
        <input
          className={inputClasses}
          name={name}
          ref={inputRef}
          type="text"
          value={formik.values[field]}
          disabled={disabled}
          onChange={onChange}
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
        />
        {error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

InputDate.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  field: PropTypes.string.isRequired,
  formClass: PropTypes.string,
  formik: formikPropTypes.isRequired,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  name: PropTypes.string
}

const isMemoizable = (prev, next) =>
  prev.field === next.field &&
  prev.disabled === next.disabled &&
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]


export default memo(InputDate, isMemoizable)