import axios from 'axios'
import { toast } from 'react-toastify'
import { headers, formatAppointmentSchedulesData } from './utils'

const updateAppointmentSchedules = (data = {}) => new Promise((resolve, reject) => {
  axios.patch(`/agenda/appointment_schedules/${data.id}`, formatAppointmentSchedulesData(data), { headers } )
    .then(() => {
      toast.success('Les dates du rendez-vous ont bien été mis à jour')
      resolve()
    }).catch(({ response }) => {
      toast.error('Une erreur est survenue')
      reject(response.data)
    })
})

export { updateAppointmentSchedules }
