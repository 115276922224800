import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ButtonGroup = ({ btns }) => {
  const setActiveClass = active => classNames('btn btn-sm', {
    'btn-primary': active,
    'btn-outline-primary': !active
  })

  return (
    <div className="btn-group" role="group">
      {btns.map(({ key, value, isActive, onClick }) => (
        <button
          key={key}
          type="button"
          className={setActiveClass(isActive(key))}
          onClick={() => onClick(key)} // eslint-disable-line react/jsx-no-bind
        >
          {value}
        </button>
      ))}
    </div>
  )
}

ButtonGroup.propTypes = {
  btns: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isActive: PropTypes.func.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired
}

export default ButtonGroup
