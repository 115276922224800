import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'

const IconList = ({ list }) => (
  <div className="d-flex flex-column">
    {list.map(({ icon, title, value }) => (
      <div className="d-flex flex-row align-items-center mb-3" key={title + value}>
        <Icon name={icon} title={title} className="mr-3" />
        <p className="font-weight-light m-0">{value || value === 0 ? value : 'N.C'}</p>
      </div>
    ))}
  </div>
)

IconList.propTypes = {
  list : PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ).isRequired
}

export default IconList
