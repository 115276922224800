import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { Calendar, NewAppointment, EditAppointment, EditAppointmentSchedules } from './components'

const router = createBrowserRouter([{
  path: '/agenda',
  element: <Calendar />,
  children: [
    { path: 'new_appointment', element: <NewAppointment /> },
    { path: 'edit_appointment/:id', element: <EditAppointment /> },
    { path: 'edit_appointment_schedules/:id', element: <EditAppointmentSchedules /> }
  ]
}])

export default router
