import React from 'react'

const ListTableHeader = () => (
  <>
    <th className="table-header"><div>Créneau</div></th>
    <th className="table-header" colSpan="2"><div>Service</div></th>
    <th className="table-header"><div>Destinataire</div></th>
    <th className="table-header"><div>Opérateur</div></th>
    <th className="table-header"><div>Emplacement</div></th>
  </>
)

export default ListTableHeader
