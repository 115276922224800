import React from 'react'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import frLocale from '@fullcalendar/core/locales/fr'

const calendarFetchEventStructure = element => {
  const mandatoryFields = {
    availabilityId: element.id,
    classNames: [`event-color-${element.service.color}`],
    serviceId: element.service.id,
    title: element.service.name
  }

  if (element.recurrent) {
    return {
      ...mandatoryFields,
      recurrent: true,
      daysOfWeek: element.days_of_week,
      startTime: moment(element.start_time).format('HH:mm'),
      endTime: moment(element.end_time).format('HH:mm'),
      startRecur: element.recurrence_start_at,
      endRecur: moment(element.recurrence_end_at).add(1, 'days').toDate(),
      editable: false
    }
  }

  return {
    ...mandatoryFields,
    start: element.start_at,
    end: element.end_at
  }
}

const calendarCreateEventStructure = (info, formData) => {
  const mandatoryFields = { service_id: formData.id }

  if (formData.recurrent) {
    const startTime = moment(info.date)
    const startTimeEndOfDay = moment(info.date).endOf('day')
    let endTime = moment(info.date).add(formData.duration, 'minutes')

    if (startTimeEndOfDay < endTime)
      endTime = startTimeEndOfDay

    return {
      ...mandatoryFields,
      recurrent: true,
      days_of_week: formData.daysOfWeek,
      recurrence_start_at: formData.recurrenceStart,
      recurrence_end_at: formData.recurrenceEnd,
      start_time: startTime.format('HH:mm'),
      end_time: endTime.format('HH:mm')
    }
  }

  return {
    ...mandatoryFields,
    start_at: info.dateStr,
    end_at: moment(info.date).add(formData.duration, 'minutes').toISOString()
  }
}

const calendarFetchEvent = (_info, successCallback, failureCallback) => {
  let url = '/availabilities.json'
  const calendarId = $('#availabilities-calendar-picker :selected').val()

  if (calendarId.length !== 0)
    url = `${url}?calendar=${calendarId}`

  $.ajax(url, {
    type: 'GET',
    success: result => successCallback(result.map(calendarFetchEventStructure)),
    error: (jqXHR, _textStatus, errorThrown) => failureCallback(errorThrown, jqXHR)
  })
}

const calendarCreateEvent = (info, formData) => {
  $.ajax('/availabilities', {
    type: 'POST',
    data: {
      calendar: $('#availabilities-calendar-picker').val(),
      availability: calendarCreateEventStructure(info, formData)
    },
    success: () => info.view.calendar.refetchEvents()
  })
}

const calendarDateClick = info => {
  const modal = $('#add-availability')
  const submitButton = modal.find('button[type="submit"]')

  submitButton.attr('disabled', false).text('Ajouter')
  modal.modal('show')
  modal.one('hidden.bs.modal', () => submitButton.off('click'))

  submitButton.one('click', () => {
    submitButton.attr('disabled', true).text('Veuillez patienter...')

    const service = modal.find('#select-service option[selected="selected"]')
    let recurrent = false

    if (modal.find('input[name="availability[recurrent]"]:checked').val() === 'on')
      recurrent = true

    if (service.length > 0) {
      let duration = (parseInt(modal.find('#input-duration-hours').val(), 10) * 60) +
                      parseInt(modal.find('#input-duration-minutes').val(), 10)

      if (isNaN(duration) || duration < 5 || duration > 10079)
        duration = service.data('duration')

      calendarCreateEvent(info, {
        id: service.val(),
        name: service.text(),
        color: service.data('color'),
        duration,
        recurrent,
        daysOfWeek: modal.find('#select-days-of-week').val(),
        recurrenceStart: $('#input-recurrence-start-at').val(),
        recurrenceEnd: $('#input-recurrence-end-at').val()
      })

      if (recurrent)
        modal.find('label.ml-2.ts-label').trigger('click')
      modal.find('#select-service').selectpicker('val', null)
      modal.find('#input-duration-hours').val(0)
      modal.find('#input-duration-minutes').val(0)
      modal.find('#select-days-of-week').selectpicker('val', null)
    }

    modal.modal('hide')
  })
}

const calendarClickEvent = info => {
  const { availabilityId } = info.event.extendedProps

  if (!availabilityId)
    return

  const modal = $('#delete-availability')

  modal.find('.text-message').removeClass('d-none')
  modal.find('.loading').addClass('d-none')
  modal.find('.normal-submit, .recurrent-submit').addClass('d-none')
  modal.find('button').attr('disabled', false)

  if (info.event.extendedProps.recurrent) {
    const submitButtons = modal.find('.recurrent-submit button')

    modal.find('.recurrent-submit').removeClass('d-none')
    modal.one('hidden.bs.modal', () => submitButtons.off('click'))

    submitButtons.one('click', event => {
      submitButtons.attr('disabled', true)
      modal.find('.text-message').addClass('d-none')
      modal.find('.loading').removeClass('d-none')

      $.ajax(`/availabilities/${availabilityId}`, {
        type: 'DELETE',
        data: {
          calendar: $('#availabilities-calendar-picker').val(),
          destroy_recurrence: event.target.classList.contains('btn-secondary'),
          start_at: info.event.startStr
        },
        success: () => {
          info.view.calendar.refetchEvents()
          modal.modal('hide')
        }
      })
    })
  } else {
    const submitButton = modal.find('.normal-submit button.btn-primary')

    modal.find('.normal-submit').removeClass('d-none')
    modal.one('hidden.bs.modal', () => submitButton.off('click'))

    submitButton.one('click', () => {
      submitButton.attr('disabled', true)
      modal.find('.text-message').addClass('d-none')
      modal.find('.loading').removeClass('d-none')

      $.ajax(`/availabilities/${availabilityId}`, {
        type: 'DELETE',
        data: { calendar: $('#availabilities-calendar-picker').val() },
        success: () => {
          info.view.calendar.refetchEvents()
          modal.modal('hide')
        }
      })
    })
  }

  modal.modal('show')
}

const calendarEditEvent = info => {
  const { availabilityId } = info.event.extendedProps

  if (!availabilityId) {
    info.revert()
    return
  }

  $.ajax(`/availabilities/${availabilityId}`, {
    type: 'PATCH',
    data: {
      calendar: $('#availabilities-calendar-picker').val(),
      availability: {
        start_at: info.event.startStr,
        end_at: info.event.endStr
      }
    },
    error: info.revert
  })
}

const Availabilities = () => {
  const timeGridView = {
    allDaySlot: false,
    slotDuration: '00:15:00',
    scrollTime: `${new Date().getHours().toString().padStart(2, '0')}:00`
  }

  return (
    <div className="availabilities-calendar">
      <FullCalendar
        plugins={[bootstrapPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        themeSystem="bootstrap"
        locale={frLocale}
        headerToolbar={{
          left: 'today prev next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek'
        }}
        views={{ timeGrid: timeGridView }}
        events={calendarFetchEvent}
        dateClick={calendarDateClick}
        eventClick={calendarClickEvent}
        eventDrop={calendarEditEvent}
        eventResize={calendarEditEvent}
        nowIndicator
        editable
      />
    </div>
  )
}

export default Availabilities
