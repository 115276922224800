import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components'
import { formikPropTypes } from './utils'

const defaultIcon = {}

const Input = ({
  field,
  formik,
  error = '',
  icon = defaultIcon,
  type = 'text',
  disabled = false,
  formClasse = '',
  name= '',
  ...rest
}) => {
  const inputClasses = classNames('form-control', { 'is-invalid': Boolean(error) })
  const formClasses = classNames('form-group row', formClasse)

  const onChange = useCallback(({ target }) => {
    formik.setFieldValue(field, target.value)
  }, [field])

  return (
    <div className={formClasses}>
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>
      <div className="col-11">
        <input
          className={inputClasses}
          type={type}
          value={formik.values[field] || ''}
          disabled={disabled}
          name={name}
          onChange={onChange}
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
        />
      </div>
    </div>
  )
}

Input.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  field: PropTypes.string.isRequired,
  formClasse: PropTypes.string,
  formik: formikPropTypes.isRequired,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  name: PropTypes.string,
  type: PropTypes.string
}

const isMemoizable = (prev, next) =>
  prev.disabled === next.disabled &&
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]



export default memo(Input, isMemoizable)
