import React from 'react'
import { useLocation, Link } from 'react-router-dom'

const TypeFilter = () => {
  const { state } = useLocation()
  const month = ['month', 'fa-regular fa-calendar-days', ' Calendrier']
  const list = ['list', 'fa-solid fa-list-ul', ' Liste']
  const [calendar, className, text] = state?.calendar === 'list' ? month : list


  return (
    <Link
      className="btn agenda-controls-btn agenda-calendar-controls-btn "
      state={{ ...state, calendar }}
    >
      <i className={className} />
      {text}
    </Link>
  )
}

export default TypeFilter
