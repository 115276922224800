import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import fr from 'locales/fr'
import { useNavigate, useLocation } from 'react-router-dom'
import DatePicker, { Calendar } from 'react-multi-date-picker'
import { ServiceFilter, RecipientFilter, AgendaFilter } from './filters'
import { LinkToExportPdf, LinkToAvailabilities, LinkToCalendars } from '../links'
import { CustomInput } from '..'

const SideBar = ({ date, calendar = 'month' }) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dateObj = moment(date).toDate()

  const updateDate = useCallback(({ month: { number }, day, year }) => {
    const newDate = moment(`${year}-${number}-${day}`, 'YYYY-M-DD').format('YYYY-MM-DD')
    navigate(null, { state: { ...state, date: newDate } })
  }, [state])

  return (
    <div className='agenda-sidebar px-4 pb-3'>
      <div className='agenda-minicalendar'>
        <Calendar
          value={dateObj}
          className='p-0'
          locale={fr}
          weekStartDayIndex={1}
          monthYearSeparator=""
          headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
          showOtherDays
          onMonthChange={updateDate}
          onYearChange={updateDate}
          onFocusedDateChange={updateDate}
        />
      </div>
      <div className='agenda-datepicker'>
        <DatePicker
          value={dateObj}
          className='p-0'
          locale={fr}
          weekStartDayIndex={1}
          monthYearSeparator=""
          headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
          render={<CustomInput />}
          inputMode="none"
          editable={false}
          format='DD/MM/YYYY'
          showOtherDays
        />
      </div>
      <LinkToAvailabilities />
      <LinkToExportPdf date={date} />
      <div className='sidebar-subtitle pt-3 pb-2'>
        Filtrer mon agenda
      </div>
      <AgendaFilter calendar={calendar} />
      <div className='sidebar-selectors mt-3'>
        <ServiceFilter />
        <RecipientFilter />
      </div>
      <LinkToCalendars />
    </div>
  )
}

SideBar.propTypes = {
  calendar: PropTypes.oneOf(['day', 'week', 'month', 'list']),
  date: PropTypes.string.isRequired
}

export default SideBar
