import axios from 'axios'
import { toast } from 'react-toastify'
import { headers, formatAppointmentData, formatFormAppointmentData } from './utils'

const getAppointment = (id, userId) => axios.get(
  `/agenda/appointments/${id}`,
  { params: { user_id: userId } }
).then(({ data }) => data).catch(() => toast.error('Une erreur est survenue'))

const getAppointments = (data = {}) =>
  axios.get('/agenda/appointments', formatAppointmentData(data) )
    .then(res => res.data)
    .catch(() => toast.error('Une erreur est survenue'))

const createAppointment = (data = {}) => new Promise((resolve, reject) => {
  axios.post('/agenda/appointments', formatFormAppointmentData(data), { headers } )
    .then(() => {
      toast.success('Le rendez-vous a bien été créé')
      resolve()
    }).catch(({ response }) => {
      toast.error('Une erreur est survenue')
      reject(response.data)
    })
})

const updateAppointment = (data = {}) => new Promise((resolve, reject) => {
  if (!['preset', 'approved'].includes(data.status)) {
    toast.warning('Vous ne pouvez pas modifier les horaires de ce rendez-vous')
    return
  }

  axios.patch(`/agenda/appointments/${data.id}`, formatFormAppointmentData(data), { headers } )
    .then(() => {
      toast.success('Le rendez-vous a bien été mis à jour')
      resolve()
    }).catch(({ response }) => {
      toast.error('Une erreur est survenue')
      reject(response.data)
    })
})

const deleteAppointment = id => axios.delete(`/agenda/appointments/${id}`, { headers })
  .then(() => toast.success('Le rendez-vous a bien été supprimé'))
  .catch(() => toast.error('Une erreur est survenue'))

const redoAppointment = id => axios.patch(`/agenda/appointment_revives/${id}`, {}, { headers })
  .then(() => toast.success('Le rendez-vous a bien été relancé'))
  .catch(error => {
    const message = error.responseJSON?.message

    if (message)
      toast.warning(message)
    else
      toast.error('Une erreur est survenue')
  })

const confirmAppointment = id => axios.patch(`/agenda/appointment_confirmations/${id}`, {}, { headers })
  .then(() => toast.success('Le rendez-vous a bien été approuvé'))
  .catch(() => toast.error('Une erreur est survenue'))

const refuseAppointment = id => axios.delete(`/agenda/appointment_confirmations/${id}`, { headers })
  .then(() => toast.success('Le rendez-vous a bien été refusé'))
  .catch(() => toast.error('Une erreur est survenue'))

const cancelAppointment = id => axios.patch(`/agenda/appointment_cancels/${id}`, {}, { headers })
  .then(() => toast.success('Le rendez-vous a bien été annulé'))
  .catch(() => toast.error('Une erreur est survenue'))

export {
  getAppointment,
  getAppointments,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  redoAppointment,
  confirmAppointment,
  refuseAppointment,
  cancelAppointment
}
