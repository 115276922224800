import React, { useEffect, useRef, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const CalendarFilter = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const ref = useRef()
  const options = [
    { key: 'Mensuel', value: 'month' },
    { key: 'Hebdomadaire', value: 'week' },
    { key: 'Quotidienne', value: 'day' },
    { key: 'Liste', value: 'list' }
  ]

  const onChange = useCallback(({ target }) => {
    navigate(null, { state: { ...state, calendar: target.value } })
  }, [state])

  useEffect(() => { $(ref.current).selectpicker() }, [])

  return (
    <select
      className='agenda-dropdown pr-2 agenda-calendar-dropdown'
      ref={ref}
      value={state?.calendar}
      onChange={onChange}
    >
      {options.map(({ key, value }) => <option key={key} value={value}>{key}</option>)}
    </select>
  )
}

export default CalendarFilter
