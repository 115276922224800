import React, { useEffect, useRef, useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AgendaContext } from 'pages/agenda/contexts'

const UserFilter = () => {
  const { userId, users } = useContext(AgendaContext)
  const { state } = useLocation()
  const ref = useRef()
  const navigate = useNavigate()

  const onChange = useCallback(({ target }) => {
    navigate(null, { state: { ...state, userId: target.value } })
  }, [state])

  useEffect(() => { $(ref.current).selectpicker() }, [])

  return (
    <select
      ref={ref}
      value={userId}
      className='agenda-dropdown pr-2'
      data-live-search-placeholder="En tant que..."
      data-live-search
      onChange={onChange}
    >
      {users.map(([groupName, usersArray]) => (
        <optgroup label={groupName} key={groupName}>
          {usersArray.map(([id, fullName]) => <option value={id} key={id}>{fullName}</option>)}
        </optgroup>
      ))}
    </select>
  )
}

export default UserFilter
