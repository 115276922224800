import React from 'react'
import PropTypes from 'prop-types'

const defaultOnChange = () => null
const defaultOnFocus = () => null

const CustomInput = ({
  value = '',
  onChange = defaultOnChange,
  onFocus = defaultOnFocus
}) => (
  <div className="input-group">
    <div className="input-group-prepend">
      <div className="input-group-text">
        <i className="fa-regular fa-calendar-days" />
      </div>
    </div>
    <input
      className="form-control"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
    />
  </div>
)

CustomInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
}

export default CustomInput
