import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import exportIcon from 'images/icons/export.svg'
import { AgendaContext } from 'pages/agenda/contexts'

const LinkToExportPdf = ({ date, mobile = false }) => {
  const { userId, services } = useContext(AgendaContext)
  const serviceIds = services.map(service => service[0])
  const linkClasses = ['btn btn-sidebar w-100', {
    'sidebar-btn': !mobile,
    'pt-2': mobile
  }]

  const exportURL = `/agenda/export.pdf?user_id=${userId}` +
                    `&service_ids=${serviceIds.join('-')}` +
                    `&start_date=${moment(date).format('YYYY-MM-DD')}` +
                    `&end_date=${moment(date).add(1, 'month').format('YYYY-MM-DD')}`

  return (
    <Link
      className={classNames(linkClasses)}
      title="Exporter"
      target="_blank"
      to={exportURL}
    >
      <img src={exportIcon} className='pr-1' />
      {/**/}
      Télécharger le PDF
    </Link>
  )
}

LinkToExportPdf.propTypes = {
  date: PropTypes.string.isRequired,
  mobile: PropTypes.bool
}

export default LinkToExportPdf
